import { AlertConfigWithDetails, AlertStatusResponse } from '@/types/Alerts'
import { PumpUser } from '@/types/PumpStations'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import AlertsHistory from './AlertsHistory'
import EditAlertModal from './EditAlertModal'

interface AlertsConfigProps {
  configs: AlertConfigWithDetails[] | undefined
  refetchConfigs: () => void
  users: PumpUser[]
  role: string
  isEmployee: boolean
}

const AlertsConfig = ({
  configs,
  refetchConfigs,
  users,
  role,
  isEmployee,
}: AlertsConfigProps): ReactElement => {
  const [editAlertModalOpen, setEditAlertModalOpen] = useState(false)
  const [editAlertModalConfig, setEditAlertModalConfig] =
    useState<AlertConfigWithDetails | null>(null)

  useEffect(() => {
    if (editAlertModalConfig !== null) {
      const updatedConfig = configs?.find(
        (conf) => conf.id === editAlertModalConfig.id
      )
      setEditAlertModalConfig(updatedConfig ? updatedConfig : null)
    }
  }, [configs, editAlertModalConfig])
  const alertsHistory: AlertStatusResponse[] = useMemo(() => {
    const statuses: AlertStatusResponse[] = []
    if (configs) {
      configs.forEach((alert) => {
        if (alert.statuses) {
          statuses.push(...alert.statuses.filter((s) => s.acknowledged))
        }
      })
    }
    return statuses
  }, [configs])
  if (!configs) {
    return <div>Loading...</div>
  }
  return (
    <div className="min-w-full mt-2">
      <h2 className="text-md font-medium leading-6 text-gray-900">
        Configuration
      </h2>
      <div className="hidden md:block overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mt-2">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Alert Condition
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
              >
                Enabled
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Subscribers
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {configs.map((config) => (
              <tr key={config.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-center font-medium text-gray-900 sm:pl-6">
                  {config.name}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                  {config.threshold_operator === '>' &&
                    `More than ${config.threshold_value}`}
                  {config.threshold_operator === '<' &&
                    `Less than ${config.threshold_value}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                  {config.enabled ? 'Yes' : 'No'}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-row">
                  <div className="flex flex-col">
                    {config.subscriptions?.map((subscription) => {
                      return (
                        <p key={subscription.id}>{subscription.display_name}</p>
                      )
                    })}
                  </div>
                  {((role && ['owner', 'user'].includes(role)) ||
                    isEmployee) && (
                    <button
                      onClick={() => {
                        setEditAlertModalConfig(config)
                        setEditAlertModalOpen(true)
                      }}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold h-10 px-4 rounded w-full md:w-20 ml-auto"
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="md:hidden">
        {configs.map((config) => (
          <div
            key={config.id}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-xl mb-2"
          >
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    {config.name}
                  </h3>
                  <span
                    className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${
                      config.enabled
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {config.enabled ? 'Enabled' : 'Disabled'}
                  </span>
                </div>
                <p className="truncate text-xs text-gray-500">
                  Alert Condition
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      {config.subscriptions?.map((subscription) => {
                        return (
                          <p key={subscription.id}>
                            {subscription.display_name}
                          </p>
                        )
                      })}
                    </h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">
                    Subscribers
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  setEditAlertModalConfig(config)
                  setEditAlertModalOpen(true)
                }}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold rounded h-10 px-4 py-2 m-6"
              >
                Edit
              </button>
            </div>

            {/* <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <a className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-red-800">
                    <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    Disable
                  </a>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <a className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                    <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    Add Subscriber
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        ))}
      </div>
      <AlertsHistory alerts={alertsHistory} />
      <EditAlertModal
        config={editAlertModalConfig}
        refetchConfigs={refetchConfigs}
        users={users}
        role={role}
        open={editAlertModalOpen}
        onClose={function (): void {
          setEditAlertModalConfig(null)
          setEditAlertModalOpen(false)
        }}
        isEmployee={isEmployee}
      />
    </div>
  )
}
export default AlertsConfig
