function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

export interface SectionProps {
  title: string
  children: React.ReactNode
}

const Section = ({ title, children }: SectionProps) => {
  return (
    <div className="mt-8 w-full">
      <div className="mx-auto max-w-6xl sm:px-6 lg:px-8">
        <h2 className="text-lg font-medium leading-6 pl-2 text-gray-900">
          {title}
        </h2>
        <div className="mt-2">
          {/* Card */}
          <div className="overflow-hidden sm:rounded-lg bg-white shadow">
            <div className="sm:p-5">
              <div className="flex items-center">
                <div className="mx-5 w-0 flex-1">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section
