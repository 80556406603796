import { AlertStatusResponse } from '@/types/Alerts'
import { AlertsConfigApiResponse } from '@/types/ApiResponse'
import axios, { AxiosPromise } from 'axios'
import { ReactElement } from 'react'

interface ActiveAlertsProps {
  alerts: AlertStatusResponse[]
  refetch: () => AxiosPromise<AlertsConfigApiResponse>
}

const ActiveAlerts = ({
  alerts,
  refetch,
}: ActiveAlertsProps): ReactElement | null => {
  if (!alerts || alerts.length === 0) return null
  return (
    <div className="mt-8 w-full">
      <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          Active Alerts
        </h2>
        <div className="mt-2">
          {/* Card */}
          <div className="overflow-hidden rounded-lg bg-red-300 shadow">
            <div className="p-5">
              <div className="flex items-center">
                <div className="sm:ml-5 w-0 flex-1">
                  <div className="flex justify-between flex-grow items-center sm:mx-4">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Activated At
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {alerts.map((alert) => (
                          <tr key={alert.id}>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                              {alert.name}
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              {new Date(alert.alerted_at).toLocaleString()}
                            </td>
                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button
                                onClick={async () => {
                                  await axios.post(
                                    `${process.env.NEXT_PUBLIC_PUMPS_BACKEND_URL}/alerts/status/${alert.id}/acknowledge`
                                  )
                                  await refetch()
                                }}
                                className="text-green-600 hover:text-green-900"
                              >
                                Acknowledge
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActiveAlerts
