interface TimeframeSelectorProps {
  timeframe: string
  setTimeframe: (timeframe: string) => void
}

interface Timeframe {
  id: string
  name: string
}

const timeframes: Timeframe[] = [
  { id: '15m', name: '15 minutes' },
  { id: '1h', name: '1 hour' },
  { id: '6h', name: '6 hours' },
  { id: '1d', name: '1 day' },
  { id: '2d', name: '2 days' },
  { id: '3d', name: '3 days' },
  { id: '7d', name: '7 days' },
]

export default function TimeframeSelector({
  timeframe,
  setTimeframe,
}: TimeframeSelectorProps) {
  return (
    <div>
      <label
        htmlFor="location"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Select Historical Timeframe
      </label>
      <select
        id="location"
        name="location"
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        defaultValue="1d"
        onChange={(e) => setTimeframe(e.target.value)}
      >
        {timeframes.map((timeframe) => (
          <option key={timeframe.id} value={timeframe.id}>
            {timeframe.name}
          </option>
        ))}
      </select>
    </div>
  )
}
